import React, { Component } from "react";

// import Custom Components
import MainLayout from "../layouts/MainLayout";
import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import Seo from "../components/common/SEO";
import { Link } from "gatsby";

import Recaptcha from "react-recaptcha";

import { encode } from "../utils";
class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "init",
      formData: {
        firstName: "",
        lastName: "",
        contact: "",
        email: "",
        message: "",
        subject: "",
      },
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: { ...prevState.formData, [name]: value },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let url = `${process.env.GATSBY_API_BASE_URL}/api/v1/marketplace/forms`;
    this.setState({ status: "submitting" });
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.GATSBY_API_ACCESS_TOKEN}`,
      },
      body: JSON.stringify({
        ...this.state.formData,
        contactRequest: {
          isContact: true,
          message: this.state.formData.message,
          subject: this.state.formData.subject,
        },
      }),
    })
      .then((res) => {
        this.setState({ status: "success" });
      })
      .catch((err) => {
        this.setState({ status: "failed" });
        console.log(err);
      });
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        "g-recaptcha-response": this.state.recaptcha,
        ...this.state.formData,
      }),
    })
      .then((res) => {
        this.setState({ status: "success" });
      })
      .catch((err) => {
        this.setState({ status: "failed" });
        console.log(err);
      });
  };

  render() {
    const { firstName, lastName, contact, email, message, subject } =
      this.state.formData;
    return (
      <MainLayout>
        <div className="main">
          <Seo title="Owls -  Wholesale Page" />
          <h1 className="d-none">OWLS614 - Wholesale Page</h1>

          <PageHeader title="Wholesale" />
          <Breadcrumb title="Wholesale" />

          <div className="about">
            <div className="container  mt-0">
              <div className="row  ">
                <div className="col-12 ">
                  <img src="/assets/images/wholesale-banner.jpg" alt="about" />
                </div>
              </div>

              <div className="row  ">
                <div className="col-12 d-flex align-items-center">
                  <div className="p-5">
                    <h2 className="title text-primary mb-1">
                      Liquidation Merchandise by the Truckload or Pallet
                    </h2>
                    <p className="lead text-dark">
                      We buy wholesale, liquidated, and surplus products at low
                      prices from our partners and pass those saving on to our
                      customers. You can buy our products in our online store or
                      at our retail location. We also offer products by pallet,
                      LTL and truckload. We have tons of liquidated merchandise
                      available through a wide variety of programs. These are
                      new overstock, shelf pulls box damage, close-outs returns.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row  ">
                <div className="col-sm-12 col-md-6 col-lg-6 m-0 pl-0 pr-0 align-items-center d-flex">
                  <img
                    src="/assets/images/truck.jpg"
                    style={{ minHeight: "100%", height: "100%" }}
                    alt="about intro"
                    className="img-fluid"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 m-0  bg-primary d-flex align-items-center">
                  <div className="p-5">
                    <p className="text-white about-body">
                      <b>
                        We have a dedicated team of professionals who make it
                        their mission to work closely with every customer to
                        ensure that they got their unique load.
                      </b>{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row  ">
                <div className="col-sm-12 col-md-6 col-lg-6  m-0  bg-primary d-flex align-items-center">
                  <div className="p-5">
                    <p className="text-white about-body">
                      <b>
                        We take pride in stocking only High-quality Merchandise
                        that comes straight out of major US department stores!
                      </b>{" "}
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 m-0 pl-0 pr-0">
                  <img
                    src="/assets/images/team.jpg"
                    className="img-fluid"
                    style={{ minHeight: "100%", height: "100%" }}
                    alt="about intro"
                  />
                </div>
              </div>
              <div className="row  ">
                <div className="col-sm-12 col-md-6 col-lg-6 border-right d-flex align-items-center">
                  <div className="p-5">
                    <h2 className="title text-primary mb-1">
                      Quality Truckloads and Pallets at Exceptional Prices
                    </h2>
                    <p className="lead text-dark">
                      We buy directly from top US retailers, with a huge
                      selection of surplus, customer return, overstock, and
                      liquidation inventory available, you're sure to find
                      exactly what you are looking for without cracking open the
                      piggy bank!
                    </p>
                  </div>
                </div>{" "}
                <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="p-5">
                    <h2 className="title text-primary mb-1">
                      Satisfaction is our Mission
                    </h2>
                    <p className="lead text-dark">
                      A truckload of high-end merchandise will disappear fast,
                      especially when it's sold at record low prices! Whenever
                      you need to restock, we'll be ready with another truck to
                      keep your inventory full and your customer satisfied.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row  ">
                <div className="col-12 text-center d-flex align-items-center m-0 justify-content-center  bg-dark">
                  <div className="p-5 ">
                    <h3 className="text-white ">
                      Our Clients Need Excellent, Quality loads. That's why they
                      hire us!
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className=" p-5 bg-primary">
              <div className="container">
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                    <div className="count-container text-center">
                      <div className="count-wrapper text-white font-weight-bold">
                        <i className="fa fa-truck"></i>
                      </div>
                      <h3 className="count-title text-white">
                        Low Shipping Rates
                      </h3>
                    </div>
                  </div>
                  <div className=" col-xs-6 col-sm-6 col-md-3 col-lg-3">
                    <div className="count-container text-center">
                      <div className="count-wrapper text-white font-weight-bold">
                        <i class="fa fa-archive"></i>
                      </div>
                      <h3 className="count-title text-white">Unprocessed</h3>
                    </div>
                  </div>
                  <div className=" col-xs-6 col-sm-6 col-md-3 col-lg-3">
                    <div className="count-container text-center">
                      <div className="count-wrapper text-white font-weight-bold">
                        <i className="fa  fa-usd"></i>
                      </div>
                      <h3 className="count-title text-white">Best Price</h3>
                    </div>
                  </div>{" "}
                  <div className="col-xs-6  col-sm-6 col-md-3 col-lg-3">
                    <div className="count-container text-center">
                      <div className="count-wrapper text-white font-weight-bold">
                        <i className="fa fa-comment"></i>
                      </div>
                      <h3 className="count-title text-white">
                        Free Consultation
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <span className="bg-overlay"></span> */}

            <div className="touch-container row justify-content-center bg-wholesale">
              <div className="col-md-8 col-lg-8">
                <div className="text-center mt-2">
                  <h2 className="title text-primary mb-1">
                    Contact us to learn about our amazing truckloads
                  </h2>
                </div>

                {this.state.status === "init" && (
                  <form
                    action="#"
                    className="contact-form mb-3"
                    name="contact"
                    method="POST"
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                    data-netlify-recaptcha="true"
                    onSubmit={this.handleSubmit}
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <input
                      type="hidden"
                      name="bot-field"
                      onChange={this.handleChange}
                    />
                    <div className="row">
                      <div className="col-sm-6">
                        <label htmlFor="name" className="sr-only">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="firstName"
                          placeholder="Enter your first name"
                          required=""
                          value={firstName}
                          onChange={this.handleChange}
                        />
                      </div>{" "}
                      <div className="col-sm-6">
                        <label htmlFor="cname" className="sr-only">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cname"
                          name="lastName"
                          placeholder="Enter your last name"
                          required=""
                          value={lastName}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <label htmlFor="cemail" className="sr-only">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="cemail"
                          name="email"
                          placeholder="Email"
                          required=""
                          value={email}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <label htmlFor="ccontact" className="sr-only">
                          contact
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="ccontact"
                          name="contact"
                          placeholder="Enter your number"
                          required=""
                          value={contact}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="subject" className="sr-only">
                          Subject
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          rows="6"
                          placeholder="Write Your Subject"
                          id="subject"
                          name="subject"
                          value={subject}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <label htmlFor="cmessage" className="sr-only">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      cols="30"
                      placeholder="Write Your Message"
                      id="cmessage"
                      name="message"
                      value={message}
                      onChange={this.handleChange}
                    ></textarea>
                    <div className="col-md-12 mb-4">
                      <Recaptcha
                        sitekey={`${process.env.GATSBY_SITE_RECAPTCHA_KEY}`}
                        render="explicit"
                        onloadCallback={() => {}}
                        verifyCallback={(res) =>
                          this.setState({ recaptcha: res })
                        }
                      />
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        aria-labelledby="submit"
                        className="btn btn-primary btn-minwidth-sm"
                        disabled={
                          this.state.status === "submitting" ||
                          this.state.recaptcha === undefined
                        }
                      >
                        <span id="submit">SUBMIT</span>
                        <i className="icon-long-arrow-right"></i>
                      </button>
                    </div>
                  </form>
                )}

                {this.state.status === "success" && (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="alert " role="alert">
                          <h4 className="alert-heading">
                            Thank you for contacting us!
                          </h4>
                          <p>We will get back to you as soon as possible.</p>

                          <hr />

                          <Link to="/" className="btn btn-outline-primary-2">
                            <span> Back to Home</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.status === "failed" && (
                  <div className="success-text">
                    <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      style={{ color: "#ff4c3b" }}
                    ></i>
                    <h2>Oops!</h2>
                    <p>
                      Something went wrong while submitting the response. Please
                      refresh the page and try again.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 ">
              <div className=" p-3 bg-primary text-white  text-center">
                <a href="tel:614-625-6065">
                  {" "}
                  <h2 className="text-white">
                    {" "}
                    Or call us today! 614-625-6065
                  </h2>
                </a>
              </div>{" "}
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default React.memo(ContactUs);
